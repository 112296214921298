import React from "react";
import { Helmet } from "react-helmet";
import ScrollspyNav from "react-scrollspy-nav";

import HeaderLandingMobileApp from "../../../../components/header/landing/HeaderLandingMobileApp";
import FooterLandingMobileApp from "../../../../components/footer/FooterLandingMobileApp";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Termos & Condições | Transformapp
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link ativo" href="#opt1">
                      1. Termos de uso do site
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Termos e condições
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      3. Política de privacidade
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      4. Política de cookies
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      5. Links de terceiros
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                      6. GDPR
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Termos e uso do site</h2>
                    <div className="update-date">ÚLTIMA ATUALIZAÇÃO: 26/06/2020</div>
                    <h3>1. Introdução</h3>
                    <p>
                      Tomamos uma série de medidas para cumprir o GDPR.
                      Para obter mais detalhes sobre nossa conformidade e como lidamos com
                      seus dados, verifique nossos documentos legais mais recentes abaixo:
                    </p>
                    <ul>
                      <li>
                        <a href="#">Política de privacidade</a>
                      </li>
                      <li>
                        <a href="#">Termos e condições</a>
                      </li>
                    </ul>
                    <h3>2. Sobre nós</h3>
                    <p>
                      Quando você usa o Ticket Tailor para vender ingressos e
                      coletando dados de seus participantes, você é o “dado
                      controlador” de todos os dados dos seus participantes. Somos um “dados
                      processador”, o que significa que não faremos nada com
                      os dados dos seus participantes além daqueles que você precisa que façamos
                      para fornecer nosso serviço. Não enviaremos seu
                      participantes quaisquer e-mails de marketing, compartilhando seus dados com
                      qualquer pessoa e se quiser que excluamos os dados é só pedir.
                      São seus dados. 100
                    </p>
                    <h3>3. Acessando nosso site</h3>
                    <p>
                      Os servidores e bancos de dados que alimentam o Ticket Tailor são
                      localizado na UE na Irlanda. Inevitavelmente usamos um pequeno
                      número de terceiros, alguns dos quais estão fora do
                      UE, para fornecer o nosso serviço de bilhética e temos contratos
                      com cada um deles que os obriga a cumprir os
                      GDPR. Você pode encontrar a lista completa desses terceiros
                      e para que os usamos aqui.
                    </p>
                  </div>
                  <div id="opt2">
                    <h2 className="font-gilroy-bold">Termos e Condições</h2>
                    <div className="update-date">ÚLTIMA ATUALIZAÇÃO: 26/06/2020</div>
                    <h3>1. Introdução</h3>
                    <p>
                      Esta página, juntamente com a nossa Política de Privacidade e o
                      Os Termos de Uso do site fornecem informações sobre nós e
                      os termos e condições legais (os "Termos") sob os quais
                      disponibilizar para você nossa solução de venda de ingressos online
                      (o serviço").
                    </p>
                    <h3>2. Informações sobre nós</h3>
                    <p>
                      Tickettailor.com e buytickets.at e qualquer um de seus
                      subdomínios é um site operado pela Ticket Tailor, que é
                      o nome comercial da Zimma Ltd ("nós", “nosso”, “nos”). Nós somos
                      registrada na Inglaterra e no País de Gales sob o número de empresa
                      07583551 e temos sede na Unidade 6, 14A
                      Andre St, Londres, E8 2AA. Nosso número de IVA é GB184622203.
                    </p>
                    <p>
                      Você pode entrar em contato conosco enviando um e-mail para{" "}
                      <a href="#">hi@deski.com</a>.
                    </p>
                  </div>
                  <div id="opt3">
                    <h2 className="font-gilroy-bold">Política de privacidade</h2>
                    <div className="update-date">ÚLTIMA ATUALIZAÇÃO: 26/06/2020</div>
                    <h3>1. Introdução</h3>
                    <p>
                      Tomamos uma série de medidas para cumprir o GDPR.
                      Para obter mais detalhes sobre nossa conformidade e como lidamos com
                      seus dados, verifique nossos documentos legais mais recentes abaixo:
                    </p>
                    <ul>
                      <li>
                        <a href="#">Política de privacidade</a>
                      </li>
                      <li>
                        <a href="#">Termos e condições</a>
                      </li>
                    </ul>
                    <h3>2. Sobre nós</h3>
                    <p>
                      Quando você usa o Ticket Tailor para vender ingressos e
                      coletando dados de seus participantes, você é o “dado
                      controlador” de todos os dados dos seus participantes. Somos um “dados
                      processador”, o que significa que não faremos nada com
                      os dados dos seus participantes além daqueles que você precisa que façamos
                      para fornecer nosso serviço. Não enviaremos seu
                      participantes quaisquer e-mails de marketing, compartilhando seus dados com
                      qualquer pessoa e se quiser que excluamos os dados é só pedir.
                      São seus dados. 100
                    </p>
                    <h3>3. Acessando nosso site</h3>
                    <p>
                      Os servidores e bancos de dados que alimentam o Ticket Tailor são
                      localizado na UE na Irlanda. Inevitavelmente usamos um pequeno
                      número de terceiros, alguns dos quais estão fora do
                      UE, para fornecer o nosso serviço de bilhética e temos contratos
                      com cada um deles que os obriga a cumprir os
                      GDPR. Você pode encontrar a lista completa desses terceiros
                      e para que os usamos aqui.
                    </p>
                  </div>
                  <div id="opt4">
                    <h2 className="font-gilroy-bold">Política de cookies</h2>
                    <div className="update-date">ÚLTIMA ATUALIZAÇÃO: 26/06/2020</div>
                    <h3>1. Introdução</h3>
                    <p>
                      Tomamos uma série de medidas para cumprir o GDPR.
                      Para obter mais detalhes sobre nossa conformidade e como lidamos com
                      seus dados, verifique nossos documentos legais mais recentes abaixo:
                    </p>
                    <ul>
                      <li>
                        <a href="#">Política de privacidade</a>
                      </li>
                      <li>
                        <a href="#">Termos e condições</a>
                      </li>
                    </ul>
                    <h3>2. Sobre nós</h3>
                    <p>
                      Quando você usa o Ticket Tailor para vender ingressos e
                      coletando dados de seus participantes, você é o “dado
                      controlador” de todos os dados dos seus participantes. Somos um “dados
                      processador”, o que significa que não faremos nada com
                      os dados dos seus participantes além daqueles que você precisa que façamos
                      para fornecer nosso serviço. Não enviaremos seu
                      participantes quaisquer e-mails de marketing, compartilhando seus dados com
                      qualquer pessoa e se quiser que excluamos os dados é só pedir.
                      São seus dados. 100
                    </p>
                    <h3>3. Acessando nosso site</h3>
                    <p>
                      Os servidores e bancos de dados que alimentam o Ticket Tailor são
                      localizado na UE na Irlanda. Inevitavelmente usamos um pequeno
                      número de terceiros, alguns dos quais estão fora do
                      UE, para fornecer o nosso serviço de bilhética e temos contratos
                      com cada um deles que os obriga a cumprir os
                      GDPR. Você pode encontrar a lista completa desses terceiros
                      e para que os usamos aqui.
                    </p>
                  </div>
                  <div id="opt5">
                    <h2 className="font-gilroy-bold">Links de terceiros</h2>
                    <div className="update-date">ÚLTIMA ATUALIZAÇÃO: 26/06/2020</div>
                    <h3>1. Introdução</h3>
                    <p>
                      Tomamos uma série de medidas para cumprir o GDPR.
                      Para obter mais detalhes sobre nossa conformidade e como lidamos com
                      seus dados, verifique nossos documentos legais mais recentes abaixo:
                    </p>
                    <ul>
                      <li>
                        <a href="#">Política de privacidade</a>
                      </li>
                      <li>
                        <a href="#">Termos e condições</a>
                      </li>
                    </ul>
                    <h3>2. Sobre nós</h3>
                    <p>
                      Quando você usa o Ticket Tailor para vender ingressos e
                      coletando dados de seus participantes, você é o “dado
                      controlador” de todos os dados dos seus participantes. Somos um “dados
                      processador”, o que significa que não faremos nada com
                      os dados dos seus participantes além daqueles que você precisa que façamos
                      para fornecer nosso serviço. Não enviaremos seu
                      participantes quaisquer e-mails de marketing, compartilhando seus dados com
                      qualquer pessoa e se quiser que excluamos os dados é só pedir.
                      São seus dados. 100
                    </p>
                    <h3>3. Acessando nosso site</h3>
                    <p>
                      Os servidores e bancos de dados que alimentam o Ticket Tailor são
                      localizado na UE na Irlanda. Inevitavelmente usamos um pequeno
                      número de terceiros, alguns dos quais estão fora do
                      UE, para fornecer o nosso serviço de bilhética e temos contratos
                      com cada um deles que os obriga a cumprir os
                      GDPR. Você pode encontrar a lista completa desses terceiros
                      e para que os usamos aqui.
                    </p>
                  </div>
                  <div id="opt6">
                    <h2 className="font-gilroy-bold">GDPR</h2>
                    <div className="update-date">ÚLTIMA ATUALIZAÇÃO: 26/06/2020</div>
                    <h3>1. Introdução</h3>
                    <p>
                      Tomamos uma série de medidas para cumprir o GDPR.
                      Para obter mais detalhes sobre nossa conformidade e como lidamos com
                      seus dados, verifique nossos documentos legais mais recentes abaixo:
                    </p>
                    <ul>
                      <li>
                        <a href="#">Política de privacidade</a>
                      </li>
                      <li>
                        <a href="#">Termos e condições</a>
                      </li>
                    </ul>
                    <h3>2. Sobre nós</h3>
                    <p>
                      Quando você usa o Ticket Tailor para vender ingressos e
                      coletando dados de seus participantes, você é o “dado
                      controlador” de todos os dados dos seus participantes. Somos um “dados
                      processador”, o que significa que não faremos nada com
                      os dados dos seus participantes além daqueles que você precisa que façamos
                      para fornecer nosso serviço. Não enviaremos seu
                      participantes quaisquer e-mails de marketing, compartilhando seus dados com
                      qualquer pessoa e se quiser que excluamos os dados é só pedir.
                      São seus dados. 100
                    </p>
                    <h3>3. Acessando nosso site</h3>
                    <p>
                      Os servidores e bancos de dados que alimentam o Ticket Tailor são
                      localizado na UE na Irlanda. Inevitavelmente usamos um pequeno
                      número de terceiros, alguns dos quais estão fora do
                      UE, para fornecer o nosso serviço de bilhética e temos contratos
                      com cada um deles que os obriga a cumprir os
                      GDPR. Você pode encontrar a lista completa desses terceiros
                      e para que os usamos aqui.
                    </p>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <FooterLandingMobileApp />
      {/* End Footer */}
    </div>
  );
};

export default TermsConditions;
