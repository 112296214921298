import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AllRoutes from './router/AllRoutes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'photoswipe/dist/photoswipe.css';
import 'bootstrap/dist/js/bootstrap';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener('load', AOS.refresh);

  return (
    <>
      <Helmet>
        <title>
          {/* Transformapp - Turn your business into an app */}
          Transformapp - Transforme seu Negócio em App
        </title>
        <meta property="og:site_name" content="deski" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Transformapp - Transforme seu Negócio em App" />
        <meta
          name="keywords"
          content="agency, bootstrap 5, business, clean, corporate, creative, fullpage, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company,
          startup, web design, web development, web studio, website design, website development, website template"
        />
        <meta
          name="description"
          content="Transforme seu Negócio em App com a Transformapp. A melhor solução para transformar seu negócio em um aplicativo."
        />
        <meta
          name="description"
          content="Transforme seu Negócio em App com a Transformapp. A melhor solução para transformar seu negócio em um aplicativo."
        />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      {/* End Scroll top */}

      <AllRoutes />
    </>
  );
};

export default App;
