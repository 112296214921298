import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialThree() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
  };

  const TestimonilContent = [
    {
      desc: `Adorei a ferramenta que transforma aplicativo em site! Foi super fácil de usar e me poupou muito tempo e dinheiro. Agora, meus clientes podem acessar meu aplicativo em qualquer dispositivo, sem precisar baixar nada.`,
      reviewerName: "Ana Silva",
      designation: "Empreendedora",
    },
    {
      desc: `Sempre quis ter um site para meu aplicativo, mas não tinha tempo nem dinheiro para contratar um desenvolvedor. Essa ferramenta foi a solução perfeita! Consegui criar um site profissional em minutos, sem precisar ter nenhum conhecimento técnico.`,
      reviewerName: "João Santos",
      designation: "Desenvolvedor",
    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div
          className="item"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <p>{val.desc}</p>
          <h6 className="name">{val.reviewerName}</h6>
          <span className="desig">{val.designation}</span>
        </div>
      ))}
    </Slider>
  );
}
