import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingMobileApp from "../../components/header/landing/HeaderLandingMobileApp";
import MobileAppPortfolio from "../../components/portfolio/MobileAppPortfolio";
import MobileAppScreenSlider from "../../components/portfolio/MobileAppScreenSlider";
import TestimonialThree from "../../components/testimonial/TestimonialThree";
import BrandThree from "../../components/brand/BrandThree";
import FooterSeven from "../../components/footer/FooterSeven";
import CopyRightThree from "../../components/footer/CopyRightThree";
import FancyFeatureTewentyThree, { FeatureContent } from "../../components/features/FancyFeatureTewentyThree";
import PricingSeven from "../../components/pricing/PricingSeven";
import FooterLandingMobileApp from "../../components/footer/FooterLandingMobileApp";

const MobileAppLanding = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Mobile App Landing Header */}

      {/* =============================================
            Theme Hero Banner
        ==============================================  */}
      <div className="hero-banner-ten" id="home">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
              <h1
                className="hero-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Transforme seu Negócio em App
              </h1>
              <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                Transformapp é a melhor solução para transformar seu negócio em um aplicativo.
              </p>
            </div>
          </div>
          {/* End .row */}

          <div className="d-sm-flex align-items-center justify-content-center button-group">
            <a
              href="#"
              className="d-flex align-items-center ios-button"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img src="images/icon/apple.svg" alt="icon" className="icon" />
              <div>
                <span>Baixe no</span>
                <strong>App store</strong>
              </div>
            </a>
            <a
              href="#"
              className="d-flex align-items-center windows-button"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="350"
            >
              <img
                src="images/icon/playstore.svg"
                alt="icon"
                className="icon"
              />
              <div>
                <span>Comece</span>
                <strong>Google play</strong>
              </div>
            </a>
          </div>
        </div>
        {/* End .container */}

        <div className="icon-box-one">
          <img src="images/logo/logo-39.png" alt="icon" />
        </div>
        <div className="icon-box-two">
          <img src="images/logo/logo-40.png" alt="icon" />
        </div>
        <div className="icon-box-three">
          <img src="images/logo/logo-41.png" alt="icon" />
        </div>
        <div className="icon-box-four">
          <img src="images/logo/logo-42.png" alt="icon" />
        </div>
        <div className="icon-box-five">
          <img src="images/logo/logo-43.png" alt="icon" />
        </div>
        <div className="icon-box-six">
          <img src="images/logo/logo-44.png" alt="icon" />
        </div>
        <div className="icon-box-seven">
          <img src="images/logo/logo-45.png" alt="icon" />
        </div>
        <div className="icon-box-eight">
          <img src="images/logo/logo-46.png" alt="icon" />
        </div>
      </div>
      {/* /.hero-banner-ten */}

      {/* =============================================
            App Screen Preview
        ==============================================  */}
      <div className="app-screen-preview-one">
        <div className="container">
          <div className="app-preview-slider-one arrow-middle-center ">
            <MobileAppPortfolio />
          </div>
        </div>
        {/* End .container */}

        <img
          src="images/shape/bg5.svg"
          alt="shape"
          className="shapes round-bg"
        />
        <img
          src="images/shape/216.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/217.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/218.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/219.svg"
          alt="shape"
          className="shapes shape-four"
        />
      </div>
      {/* /.app-screen-preview-one  */}

      {/* =============================================
				Fancy Feature Twenty Two
			==============================================  */}
      <div
        className="fancy-feature-twentyTwo mpt-200 pt-130 md-mt-80 sm-pt-100"
        id="product"
      >
        <div className="container">
          <div className="title-style-ten mb-40 md-mb-20">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h2>A Solução Perfeita para o Seu Negócio Online</h2>
              </div>
              <div className="col-lg-5">
                <p className="md-pt-20">
                  Descubra como nossa plataforma revolucionária pode impulsionar o seu sucesso digital.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <FancyFeatureTewentyThree />
          </div>
        </div>
      </div>
      {/* /.fancy-feature-twentyTwo */}

      {/* 	=============================================
            Fancy Feature Twenty Five
        ==============================================  */}
      <div
        className="fancy-feature-twentyFive lg-container pt-200 md-pt-100"
        id="features"
      >
        <div className="container">
          <div className="block-style-twentyFive">
            <div className="row align-items-center">
              <div
                className="col-xl-5 col-lg-5 col-md-10 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="screen-container">
                  <img src="images/assets/screen_24.png" alt="screen" />
                </div>
                {/*  /.screen-container */}
              </div>
              <div
                className="col-xl-7 col-lg-7"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper ps-xl-5">
                  <h6>
                    Mais de <span>150+ clientes</span>
                  </h6>
                  <h3 className="title">Aplicativos vencedores</h3>
                  <p style={{fontSize: 20}}>
                  Nossa solução é perfeitamente adequada para a sua empresa! Atendemos uma ampla gama de clientes, incluindo e-commerces, empresas de educação digital e empresas de entregas rápidas. No entanto, nosso produto é versátil e pode ser aplicado em qualquer ambiente web, seja um CRM, ERP ou qualquer outra plataforma. Junte-se a nós e alcance novos patamares de sucesso online! 🚀🌟
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyFive */}

          <div className="block-style-twentyFive mt-200 md-mt-100">
            <div className="row align-items-center">
              <div
                className="col-xl-7 col-lg-6 col-md-10 m-auto text-center text-lg-right order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container">
                  <img
                    src="images/shape/bg6.svg"
                    alt="shape"
                    className="ms-auto bg-round-shape"
                  />
                  <div className="block-content">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/136.svg" alt="" />
                          </div>
                          <h4>
                            {FeatureContent[1].title}
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                      </div>
                      {/* End .col */}

                      <div className="col-sm-6">
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/137.svg" alt="" />
                          </div>
                          <h4>
                            {FeatureContent[2].title}
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/138.svg" alt="" />
                          </div>
                          <h4>
                            {FeatureContent[0].title}
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                      </div>
                      {/* End .col */}
                    </div>
                  </div>
                  {/*  /.block-content */}
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-xl-5 col-lg-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper order-lg-first">
                  <h6>
                    <span>A melhor solução</span>
                  </h6>
                  <h3 className="title">Por que você escolhe Transformapp?</h3>
                  <p>Confiamos em nossa entrega de qualidade e garantimos:</p>
                  <ul>
                    <li>Garantimos a publicação</li>
                    <li>30 dias incondicional</li>
                    <li>Atualizações de Smartphones</li>
                  </ul>
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyFive */}
        </div>
      </div>
      {/*  /.fancy-feature-twentyFive */}

      {/* 	=============================================
            App Screen Preview
        ==============================================  */}
      <div className="app-screen-preview-two mt-200 md-mt-130">
        <div className="container">
          <div className="title-style-eleven text-center mb-120 md-mb-70">
            <h2>Captura de tela dos aplicativos</h2>
          </div>
        </div>

        <div className="app-preview-slider-two arrow-none">
          <MobileAppScreenSlider />
        </div>
        {/* /.app-preview-slider-two */}
      </div>
      {/* /.app-screen-preview-two */}

      {/* =====================================================
            Pricing Section Seven
        ===================================================== */}
      <div className="pricing-section-seven pt-200 md-pt-130" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
              <div className="title-style-eleven text-center mb-100 md-mb-70">
                <h2>Sem cobrança oculta, escolha seu plano.</h2>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-seven">
            <div className="row align-items-center justify-content-center">
              <PricingSeven />
            </div>
          </div>
          {/* /.pricing-table-area-seven */}
        </div>
      </div>
      {/* /.pricing-section-seven */}

      {/* =====================================================
            Client Feedback Slider Seven
        ===================================================== */}
      <div
        className="client-feedback-slider-seven mt-250 md-mt-100"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <img src="images/icon/143.svg" alt="icon" className="m-auto" />
                <div className="title-style-eleven text-center mt-30">
                  <h2>Verifique o que nosso cliente diz sobre nós.</h2>
                </div>
                {/* /.title-style-eleven */}
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8 col-lg-10 m-auto">
                <div className="clientSliderFive  mt-80 md-mt-50">
                  <TestimonialThree />
                </div>
                <img
                  src="images/shape/156.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/157.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
                <img
                  src="images/shape/158.svg"
                  alt="shape"
                  className="shapes shape-three"
                />
              </div>
            </div>
          </div>
        </div>
        {/*  /.inner-container */}
        <img src="images/shape/bg7.svg" alt="shape" className="bg-image" />
      </div>
      {/* /.client-feedback-slider-six */}

      {/* =====================================================
            Useable Tools
        ===================================================== */}
      <div className="useable-tools-section-three mt-200 mb-200 md-mt-120 md-mb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-ten">
                <h6>Nossos Parceiros</h6>
                <h2>O que usamos na Transformapp.</h2>
              </div>
              <p className="sub-text">
                Usamos as melhores ferramentas para garantir a qualidade do seu aplicativo.
              </p>
            </div>
          </div>
        </div>
        {/* /.container */}
        <div className="logo-wrapper brand-bg-white d-flex flex-wrap justify-content-center align-items-center">
          <BrandThree />
        </div>
        {/* /.logo-wrapper */}
      </div>
      {/* /.useable-tools-section-three */}

      {/* =====================================================
            Fancy Short Banner Twelve
        ===================================================== */}
      <div className="fancy-short-banner-twelve">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-10 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-ten text-center">
                <h2>Quer testar nossa a Transformapp? Baixe Agora!</h2>
                <p className="pt-25 pb-45">
                  Experimente sem riscos e veja o que a Transformapp pode fazer por você.
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <div className="d-sm-flex align-items-center justify-content-center button-group">
              <a href="#" className="d-flex align-items-center ios-button">
                <img
                  src="images/icon/apple-black.svg"
                  alt="icon"
                  className="icon"
                />
                <div>
                  <span>Baixe no</span>
                  <strong>App store</strong>
                </div>
              </a>
              <a href="#" className="d-flex align-items-center windows-button">
                <img
                  src="images/icon/playstore.svg"
                  alt="icon"
                  className="icon"
                />
                <div>
                  <span>Comece</span>
                  <strong>Google play</strong>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* /.container */}
        <img
          src="images/shape/220.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/221.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-short-banner-twelve */}

      <FooterLandingMobileApp />
      {/* End Mobile App Landing Footer */}
    </div>
  );
};

export default MobileAppLanding;
